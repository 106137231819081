/*global $:false*/
/*global escape: true */
/*jslint browser: true*/

var clickToCall = (function($) {
    var clickToCall = function() {
        /*
        This is a global click to call function. This will match phone numbers in these formats and convert them to anchors with 'tel' attributes:

        123 456 7890
        (123)456 7890
        (123) 456 7890
        (123)456-7890
        (123) 456-7890
        123.456.7890
        123-456-7890
        *This also accounts for the '1-800' scenario with a space, dot or dash separating the '1' and '800'

        Example: <a href="tel:1234567890">(123) 456-7890</a>
        */

        var phoneRegEx = new RegExp(/(1[\s.-])?([(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4})(?![^<]*>|[^<>]*<\/)/g);

        /*
        RegEx Explained

        /           Start the expression
        (1[\s.-])?  An optional '1' followed by an optional space, dot or dash (to be used in '1-800')
        [(]?        Optional left parenthesis
        \d{3}\      Look for 3 digits (area code)
        [)]?        Optional right parenthsis
        [(\s)?.-]   Optional space (after the area code) or a dot or dash
        \d{3}       Look for 3 digits (the first part of the phone num)
        [\s.-]      Either a space, dot, or dash to separate the first 3 numbers of the phone number
        \d{4}       The last 4 digits of the phone num
        (?![^<]*>|[^<>]*<\/)  This is a negative lookup. Make sure the number is not directly nested in any tag. Ex: a tag with an ID or class that has digits in this format
        /g          Global search

        */

        //search through the immediate children of the body and construct a new jQuery object from the matching elements.
        $("body *:not(iframe):not(script)").contents().each(function() {
            //return if nodeType isn't a text node (Text Node: Represents textual content in an element or attribute. No children.)
            if (this.nodeType != 3) {
                return;
            }
            //we don't want to change phone numbers that are already in a link
            if ($(this).closest('a').length) {
                return;
            }
            //test against the regEx
            var match = $(this).text().match(phoneRegEx);
            if (match === null || match.length === 0) {
                return;
            }
            var newhtml = $(this).text().replace(phoneRegEx, function(match) {
                //remove spaces, dashes, dots and parenthesis for the href
                var phonenumber = match.replace(/ /g, "").replace(/-/g, "").replace(/\./g, "").replace(/\(/g, "").replace(/\)/g, "");
                var link = '<a href="tel:' + phonenumber + '">' + match + '</a>';
                return link;
            });
            $(this).replaceWith(newhtml);
        });
    };

    /*
    This function is used for the 'click to call'. This can be used for all mobile devices.
    IOS automatically converts phone numbers, but does not recognize all phone formats (EX: 555-555-5555)
    so applying this for IOS will ensure all phone number get converted. This plugin will not break the default number conversion by IOS.
    Here is an example for calling this on IOS and Android devices:
    */

    var isAppleDevice = function() {
        "use strict";
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    };

    var isAndroidDevice = function() {
        "use strict";
        if (/Android/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    };

    /*
    If you are using infinite scrolling where new phone numbers may be added after the script has run, or other dynamic phone numbers that are populated via script after page load, you’ll need to make sure to add those as well.
    I added this functionality for the infinite scrolling for providers and locations for Milford just so we don’t have to scan the entire page again when new entries are added:
    */

    function infiniteScrollClickToCall() { //use this for infinite scrolling instead of looking through the entire site each time
        $('.DrListContainer ul li span.Phone, .LocationsList ul li span.Phone').each(function() { //only look for phone numbers that have a class of phone (this should be all of them)
            if ($(this).find('a').length === 0) { //disregard phone numbers that are already in an anchor
                var regex = /([(]?\d{3}[)]?)[(\s)?.-](\d{3})[\s.-](\d{4})/g; // Adding phone # links around phone numbers
                var text = $(this).html();
                text = text.replace(regex, "<a href='tel:$1$2$3'>$&</a>");
                $(this).html(text);
            }
        });
    }

    return {
        isAppleDevice: isAppleDevice,
        isAndroidDevice: isAndroidDevice,
        infiniteScrollClickToCall: infiniteScrollClickToCall,
        clickToCall: clickToCall
    };
})(jQuery);


(function($) {
    'use strict';
    /* Call To Action */
    // 1)  If 'Move-CTA-Here' exisits - move the CTA to that spot
    // 1A) If no right column exists put the CTA in the top right corner. A class of 'cta-top' is applied for styling (float: right and width)
    // 2)  If 'Move-CTA-Here' does not exist - check to see if there is an h1 imediately followed by an h2 and move it after that h2
    // 3)  If no h2 exists imediately after an h1, move CTA after the h1 instead
    // 4)  If no 'Move-CTA-Here', H1 or H2 exists - move CTA to the top of the cmsPageContent
    // 5)  If on an interior page (3 cols) and med size width (right col has just wrapped down) and no 'Move-CTA-Here' class exists, 
    //     move CTA to the top of the cmsPageContent so it does not get lost further down the page.

    $.fn.moveCTAPanel = function(options) {
        $.fn.moveCTAPanel.defaults = {
            LeftColTemplate: '.LeftCol', //specify the id/class of your Left Col tempate
            NoColTemplate: '.NoCols', //specify the id/class of your No Col tempate
            RightColTemplate: '.RightCol', //specify the id/class of your Right Col tempate
            InteriorTemplate: '.ThreeCol', //specify the id/class of your Three Col tempate
            ColumnThree: '#Col3', //specify the id/class of your Right Column
            SmallSizes: '.Small', //specify the sizes when page has broken down to 1 column. (May use multiple classes here.)
            MediumSizes: '.Medium', //specify the size when the right column of the interior page drops below the left col. (May use multiple classes here.)
            LargeSizes: '.Large, .XLarge', //specify the sizes when your page has the Right Col in it's correct place. (May use multiple classes here.)
            ContentName: '#Content' //if you are logged in .cmsPageContent may not exist. Provide another id/class for the content
        };
        options = $.extend({}, $.fn.moveCTAPanel.defaults, options);

        var InsertHere = "",
			Content = ".cmsPageContent";

		//if we don't have .cmsPageContent, use the optional parameter 'ContentName'
		if($('.cmsPageContent').length === 0) {
			Content = options.ContentName;
		}
		
		function moveToTop() {
			$('#CallToAction').addClass('cta-top').prependTo(Content); // 1A - Top of page for large sizes, a class of 'cta-top' is applied for styling
		}
		
		function insertAfterHeader() {
			if ($(Content).find('h1:first').next('h2').length > 0) {
				InsertHere = $(Content).find('h1:first').next('h2');
				$('#CallToAction').removeClass().insertAfter(InsertHere); // 2 - An h2 immediately following an h1
			} else {
				InsertHere = $(Content).find('h1:first');
				$('#CallToAction').removeClass().insertAfter(InsertHere); // 3 - After the first h1 on the page
			}
		}
	
        // Left column / No Column Templates OR Small sizes and Right Col / Interior
        if ($(options.LeftColTemplate + ' #CallToAction, ' + options.NoColTemplate + ' #CallToAction').length > 0 || 
            ($(options.SmallSizes).length > 0 || $(options.MediumSizes).length > 0 && $(options.RightColTemplate).length > 0) ||
            ($(options.SmallSizes).length > 0 || $(options.MediumSizes).length > 0 && $(options.InteriorTemplate).length > 0)) {
			
            if ($('.Move-CTA-Here:first').length > 0) { //always move to 'Move-CTA-Here' section if available
				if (($(options.LeftColTemplate).length > 0 || $(options.NoColTemplate).length > 0) && $(options.LargeSizes).length > 0) {
                    moveToTop();
                } else {  
                    $('#CallToAction').removeClass().appendTo('.Move-CTA-Here'); // 1 - 'Move-CTA-Here'
                }
			} else if ($(options.LeftColTemplate).length > 0 || $(options.NoColTemplate).length > 0) {
				if($(options.LargeSizes).length > 0) { //if LeftCol / NoCol and no 'Move-CTA-Here', move to the top
					moveToTop();	
				} else if ($(Content).find('h1:first').length > 0) { //Left col / No col and small / medium sizes. Insert after header if there is one
					insertAfterHeader();
				} else {
					moveToTop(); // otherwise move to the top
				}
            } else if ($(Content).find('h1:first').length > 0) { //RightCol / Interior and smaller devices
                insertAfterHeader();
            } else {
                moveToTop();
            }
        } 
        else if ($('.Move-CTA-Here:first').length === 0 && $(Content).find('h1:first').length === 0 && $(options.InteriorTemplate).length > 0) {
            // If the page is medium sized and we are on an interior template (when the right col wraps, but left col is still in original place)
            if ($(options.MediumSize).length > 0) {
                moveToTop();
            } else {
                $('#CallToAction').removeClass().prependTo(options.ColumnThree);
            }
        }
        // If the page is a large size and we have a right column, place it in the right column
        else if ($(options.LargeSizes).length > 0) {
            $(Content).removeClass().find('#CallToAction').prependTo(options.ColumnThree);
        } 
        
        // if call to action is empty or contains just spaces - remove it
        $('#CallToAction').filter(function () {
            return $.trim($(this).html()) === '';
        }).remove();
    };
}(jQuery));

var equalHeight = (function($) {
    "use strict";

    // remove inline min height from the group
    function clear(group) {
        group.css('min-height', '');
    }

    // make group equal heights
    function equalHeight(group) {

        // reset height set by this script
        clear(group);

        // defining a variable that will keep track of the height of the tallest element
        var tallest = 0;

        // loop through elements, find the tallest outer height (includes padding)
        group.each(function () {

            // this is the outer height of the element (it doesn't round up or down to whole numbers)
            var thisHeight = this.getBoundingClientRect().bottom - this.getBoundingClientRect().top;

            if (thisHeight > tallest) {
                tallest = thisHeight;
            }
        });

        // loop through elements again, individually set their min-height so that their total height (including padding) = our tallest element
        group.each(function () {

            // if this has css box-sizing: border box, set the min-height equal to the tallest
            if (isBorderBox(this)) {

                group.css('min-height', Math.ceil(tallest));

            } else {

                // if an element has padding
                if ($(this).outerHeight() > $(this).height()) {

                    // calulate how much border and padding is on the element
                    var thisPadding = $(this).outerHeight() - $(this).height();

                    // set the height of the element to the tallest, but leave room for the padding
                    group.css('min-height', Math.ceil(tallest - thisPadding));

                } else {

                    // if the element has no padding, simply set the height to our tallest
                    group.css('min-height', Math.ceil(tallest));
                }
            }
        });
    }

    // check to see if the page is using box-sizing: border-box;
    function isBorderBox(elem) {
        return window.getComputedStyle(elem).boxSizing === "border-box";
    };

    return {
        equalHeight: equalHeight,
        outerHeight: equalHeight,
        clear: clear
    };

})(jQuery);
